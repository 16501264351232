import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  CardActionArea,
  Grid,
  Box
} from "@mui/material";
import { getUser } from '../../shared/auth-service';
import ConnectingAirportsIcon from '@mui/icons-material/ConnectingAirports';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import { CalendarMonth } from '@mui/icons-material';

const Home = () => {
  const user = getUser();
  const name = user.name;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h5" style={{ color: '#8ebb37' }} fontWeight="bold" sx={{ flexGrow: 1, m: 1, }}>
                <b>Hola {name}!</b>
              </Typography>
              <Typography variant="h5" style={{ color: '#1a3072' }} fontWeight="bold" sx={{ flexGrow: 1, m: 1, }}>
                <i>Bienvenido a Tu experiencia <span style={{ color: '#00b0ef' }}><b>Talma</b></span></i>
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 20 }}>
              <iframe width="100%" height="315" src="https://www.youtube.com/embed/CrF3_YO4A6A" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            </Grid>
            <Grid item xs={6} sm={4} md={4}>
              <Box mb={2}>
                <Card sx={{ maxWidth: 200 }} style={{ backgroundColor: "#00000008" }}>
                  <CardActionArea style={{ textAlign: "center" }} href="/shiftcalendar">
                    <CalendarMonth sx={{ fontSize: 65 }} style={{ textAlign: "center", color: "#27b9d4" }} />
                    <CardContent style={{ padding: "0" }}>
                      <Typography gutterBottom variant="h5" component="div" style={{ color: "#8ebb37", fontWeight: "bold", margin: "0" }}>
                        Consulta de
                      </Typography>
                      <Typography gutterBottom variant="h5" component="div" style={{ color: "#1a3072", fontWeight: "bold" }}>
                        Turnos
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Box>
            </Grid>
            <Grid item xs={6} sm={4} md={4}>
              <Box mb={2}>
                <Card sx={{ maxWidth: 200 }} style={{ backgroundColor: "#00000008" }}>
                  <CardActionArea style={{ textAlign: "center" }} href="/EmployeeWorkShif">
                    <AlarmOnIcon sx={{ fontSize: 65 }} style={{ textAlign: "center", color: "#27b9d4" }} />
                    <CardContent style={{ padding: "0" }}>
                      <Typography gutterBottom variant="h5" component="div" style={{ color: "#8ebb37", fontWeight: "bold", margin: "0" }}>
                        Consulta de
                      </Typography>
                      <Typography gutterBottom variant="h5" component="div" style={{ color: "#1a3072", fontWeight: "bold" }}>
                        Marcaciones
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Box>
            </Grid>
            <Grid item xs={6} sm={4} md={4}>
              <Box mb={2}>
                <Card sx={{ maxWidth: 200 }} style={{ backgroundColor: "#00000008" }}>
                  <CardActionArea style={{ textAlign: "center" }} href="/overtime">
                    <MoreTimeIcon sx={{ fontSize: 65 }} style={{ textAlign: "center", color: "#27b9d4" }} />
                    <CardContent style={{ padding: "0" }}>
                      <Typography gutterBottom variant="h5" component="div" style={{ color: "#8ebb37", fontWeight: "bold", margin: "0" }}>
                        Consulta de
                      </Typography>
                      <Typography gutterBottom variant="h5" component="div" style={{ color: "#1a3072", fontWeight: "bold" }}>
                        Horas Extra
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Box>
            </Grid>
            {/* <Grid item xs={6} sm={4} md={4}>
              <Box mb={2}>
                <Card sx={{ maxWidth: 200 }} style={{ backgroundColor: "#00000008" }}>
                  <CardActionArea style={{ textAlign: "center" }} href="/PolyvalencePayment">
                    <ConnectingAirportsIcon sx={{ fontSize: 65 }} style={{ textAlign: "center", color: "#27b9d4" }} />
                    <CardContent style={{ padding: "0" }}>
                      <Typography gutterBottom variant="h5" component="div" style={{ color: "#8ebb37", fontWeight: "bold", margin: "0" }}>
                        Consulta de
                      </Typography>
                      <Typography gutterBottom variant="h5" component="div" style={{ color: "#1a3072", fontWeight: "bold" }}>
                        Polivalencia
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Box>
            </Grid> */}
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <img src="./Images/hom_tu_experiencia.jpg" alt="logo" style={{ width: '100%', height: '90%' }} />
        </Grid>
      </Grid>
    </>
  );
}

export default Home;
